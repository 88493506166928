export const APP_CONST = Object.freeze({
  AUTH_ACTIONS: {
    LOGIN: "login",
    REGISTER: "register",
  },
  TOAST_TYPE: {
    ERROR: "error",
    SUCCESS: "success",
  },
  SPORTS_ID: {
    CIRCKET: "4",
    SOCCER: "1",
    TENNIS: "2",
  },
  ALL_MARKET_IDS: {
    CRICKET: "CRICKET_IDS",
    SOCCER: "SOCCER_IDS",
    TENNIS: "TENNIS_IDS",
  },
  ODDS_TYPE: {
    BACK: "back",
    LAY: "lay",
  },
  AUTH_MODAL: {
    LOGIN: "login",
    REGISTER: "register",
    MOBILE_NUMBER: "mobileNumber",
    VERIFY_NUMBER: "verifyNumber",
    FORGOTO_PASSWORD: "forgotpassword",
    RESET_PASSWORD: "resetpassword",
  },
  TEST: {},
  PLAYER_BANK_DETAILS_TYPE: {
    Bank: "bank",
    Card: "card",
  },
  ENVIRONMENTS: {
    PRODUCTION: "production",
    DEVELOPMENT: "development",
  },
});

export const WEBSITE_THEME = {
  DARK: "dark",
  LIGHT: "light",
};

export const REGISTER_COMPLETE_SCRIPT_ID = "CompleteRegistration";

export const REGISTER_COMPLETE_SCRIPT = `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '570847040189148');
fbq('init', '1579555345780712');
fbq('init', '1265942443607417');
fbq('init', '1439285079537929');
fbq('track', 'Goa777CompleteRegistration');`;
